import React from 'react'
import Layout from '../components/layout'

import Header from '../components/Header'
import Main from '../components/Main'
import TestimonialSlider from "../components/Slider";
import Footer from '../components/Footer'

import socketIOClient from "socket.io-client";

// import {addResponseMessage, Widget} from "react-chat-widget";
import '../assets/css/chatbox.css';

export const isBrowser = () => typeof window !== 'undefined'
const rcwlib = typeof window !== `undefined` ? require("react-chat-widget") : null



const testimonials = [
  {
    "message": "My whole life I have carried a family curse and my endeavours always ended up with problems and negativity. Jenha worked with me for 21 days and now the curse is gone and I feel like a new person. And like a drain unblocked all the good things are now pouring through!",
    "author": "Alex Rivera"
  },
  {
    "message": "Jenah sent me an invitation to become her student after she performed some consulting work for me. I graciously accepted her offer and was a student for approximately 6 months. Jenha has vast knowledge and experience related to Shamanism and easily transfers her skill sets to willing students. She has taught me a number of shamanic techniques, which I am able to use and practice in my daily living experience. Jenha is patient and works at the students pace from grass roots all the way to advanced techniques. In the teaching process you will also gain a life long friend as well as advice from the ancestors. It really is an amazing learning experience. If you suffer from depression and anxiety her teachings will teach you how to control those mental health problems. Thanks so much Jenha.",
    "author": "Morgan Lee"
  },
  {
    "message": "This was the BEST session I've had in my whole life of going for counselling. Most productive meaning and enjoyable. My deepest Thanks to Spirit and Jenha. This is money best invested.",
    "author": "Private"
  },
  {
    "message": "After a session I felt lighter, happier. I was no longer caught up in my own little drama. I felt shifted.",
    "author": "Jordan Casey"
  },
  {
    "message": "Jenha takes her time with me and it feels like a visit - much more personal than a scheduled timed session. Sessions are engaging.",
    "author": "Casey Kim"
  },
  {
    "message": "The Journey: Gratitude to body parts highlighted how little appreciation I have for them. Very clear in terms of what you see, hear, feel. I feel centered now and not scattered.",
    "author": "Private"
  },
  {
    "message": "Thanks Jenha, for always giving sound advice and insight! Love you 🙂",
    "author": "Taylor Morgan"
  },
  {
    "message": "You are truly gifted, I appreciate your honesty, I don't feel like you're just a spiritual teacher but a caring mom / guide...THANK YOU Jenha. I hope to share your teachings with those I interact with... You stripped me bare, never cried, laughed so much as I did in the past month and weeks...so many emotions that had to leave me.",
    "author": "Private"
  }
]

// In your render or return method of a component
class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // socket_endpoint: '172.17.0.2:30066/chat',
      // socket_endpoint: "localhost:5000/chat",
      socket_endpoint: "https://chatbot.weoptimizeyou.co.za/chat",
      color: 'white',
      isArticleVisible: false,
      timeout: false,
      articleTimeout: false,
      article: '',
      loading: 'is-loading'
    }
    this.handleOpenArticle = this.handleOpenArticle.bind(this)
    this.handleCloseArticle = this.handleCloseArticle.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
        this.setState({loading: ''});
    }, 100);
    document.addEventListener('mousedown', this.handleClickOutside);
    this.socket = socketIOClient.connect(this.state.socket_endpoint);
    this.socket.on('connect', function() {
          this.emit('pjchat', "I need some help")
        }
    );
    this.socket.on('message', (m) => {
      console.log("got a message", m)
      {isBrowser() && rcwlib && rcwlib.addResponseMessage(m)}

    })
    this.socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err}`);
});
  }

  handleNewUserMessage = (newMessage) => {
    console.log('New message incoming!', newMessage);
    this.socket.emit('pjchat', newMessage) // change 'red' to this.state.color
    // Now send the message throught the backend API
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOpenArticle(article) {

    this.setState({
      isArticleVisible: !this.state.isArticleVisible,
      article
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        articleTimeout: !this.state.articleTimeout
      })
    }, 350)

  }

  handleCloseArticle() {

    this.setState({
      articleTimeout: !this.state.articleTimeout
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
        article: ''
      })
    }, 350)

  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle();
      }
    }
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div className={`body ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>
          <div id="wrapper">
            <Header onOpenArticle={this.handleOpenArticle} timeout={this.state.timeout} />
            <Main
              isArticleVisible={this.state.isArticleVisible}
              timeout={this.state.timeout}
              articleTimeout={this.state.articleTimeout}
              article={this.state.article}
              onCloseArticle={this.handleCloseArticle}
              setWrapperRef={this.setWrapperRef}
            />

            <div style={{maxWidth: '80%', height: '300px', overflow: 'hidden' }} className="flex">

              {/*<TestimonialSlider testimonials={testimonials} />*/}

            </div>
            <Footer timeout={this.state.timeout} />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

              //{isBrowser() && rcwlib && <rcwlib.Widget
                  //className={"chat"}
                  //handleNewUserMessage={this.handleNewUserMessage}
                  //title="Chat for more info?"
                  //subtitle="Or setup a call or meeting"
                  //typing={true}
              ///>
              //}

import PropTypes from 'prop-types'
import React from 'react'
import front from '../images/front800.png'
import medicine from '../images/medicine.png'
import table from '../images/table.jpg'
import {navigate} from 'gatsby'; //import navigate from gatsby

class Main extends React.Component {
    render() {
        let close = (
            <div
                className="close"
                onClick={() => {
                    this.props.onCloseArticle()
                }}
            ></div>
        )
        const item = (header, text) =>  (
            <div>
            <li>
                <h2>{header}</h2>
                {text}
            </li>
            <br/>
            </div>
        )

        return (
            <div
                ref={this.props.setWrapperRef}
                id="main"
                style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}
            >
                <article
                    id="intro"
                    className={`${this.props.article === 'intro' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                    }`}
                    style={{display: 'none'}}
                >
                    <h2 className="mysticalEffect major">Intro</h2>
                    <span className="image main">
            <img src={front} alt=""/>
          </span>
                    <p>
                        I take my calling very seriously and my intention is to empower my clients.
                    Your reality will be determined by your actions.
                    Your actions will be determined by your thoughts and words.
                    My energy is to assist you in creating the best version of yourself,
                        by using proven shamanic techniques to bring you into the present without attachment to the past through awareness.
                    </p>
                    <p>
                        Born with the gift of empathy (psychic) and on a mission to 'find God' since age 13, I began
                        studying all aspects of religion and the occult. Reading Palms and exploring my psychic
                        abilities - together with automatic writing, clairvoyance and clairaudience I was called after a
                        death experience to embrace my calling as a profession and full time - shaman/energy consultant.

                        I have been fortunate enough to meet many amazing teachers along the path into shamanism.

                        I walk the path of the wounded healer and use my own experience through serious challenges to
                        empower and inspire my clients into a new positive acceptance and appreciation of their reality
                        in the present moment.

                        I make God - Great Spirit - Great giver of ALL life - my religion - and not religion my God...


                    </p>
                    {close}
                </article>

                <article
                    id="work"
                    className={`${this.props.article === 'services' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                    }`}
                    style={{display: 'none'}}
                >
                    <h2 className="mysticalEffect major ">Services</h2>
                    <span className="image main">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img style={{width: '20vw', align: 'center'}} src={medicine} alt=""/>
              </div>
          </span>
                    <div>
                        <nav>
                            <ul>
                                <li>






                                    <h2>
                                        READINGS
                                    </h2>
                                    <h3>
                                        Individual Reading
                                    </h3>


                                    Readings are based around the Medicine Wheel - taking all aspects of a client into consideration.They are in depth, practical and accurate.

                                    Making use of :

                            <ul>
                                <li> Automatic Writing </li>
                                    <li>Clairaudience</li>
                                    <li>Tarot</li>
                                    <li>Angel Cards</li>
                                    <li>Wisdom Cards</li>
                                    <li>Medicine Cards</li>
                                    <li>Throwing the Crystals</li>
                                    <li>Pendulum</li>
                            </ul>

                                    In a distant reading, the full name, date of birth and a recent photograph of the client is required.
                                    Using the Elements, Tarot and Automatic Writing, while channeling from the guides, the messages are received and given to the client.

                                    <h3>
                                        COUPLE READING - same as above
                                    </h3>


                                </li>
                                <br/>


                                <li>

                                    <h2>
                                        YOUR WEEK AT A GLANCE
                                    </h2>

                                    Once a week 7 cards are pulled and the messages given to the client. The clients also get daily inspirational messages.
                                    This also helps the client, as I am available via WA.

                                </li>
                                <br/>
                                <li>

                                    <h2>
                                        REMOVAL OF ATTACHMENT AND ENERGY SHIFT
                                    </h2>


                                    Negative energies preventing the client from moving forward and embracing the present moment. An attachment is an entity which feeds on negative energy. They can attach due to serious trauma - arguments - crime - or at any time the client was not fully conscious.  After the removal - the client is advised of a three week energy period. During these three weeks the client will be asked to do a few simple exercises which would not take up more than 10 minutes per day. To remove an attachment is very deep shamanic work and poses a great risk to the shaman.  To (As Christ said - our fight is not with flesh and blood - but with spirits and principalities) .
                                </li>
                                <br/>

                                <li>

                                    <h2>
                                        BREAKING OF CURSES / SPELLS
                                    </h2>
                                    There are many different kinds of curses and spells. Witchcraft - Voodoo - Generational - and even simple words can be blessings or curses.
                                    Dependant on the client and the issue - these curses will be broken - and the client will be given an energy shift and possibly a removal dependant on the circumstances.
                                    I only work with free will to the HIGHEST GOOD of the client. I am therefore not interested in making 'spells' - but in BREAKING ALL NEGATIVITY.
                                    This work is very intense and poses a great threat to the shaman. The cost is therefore determined by the curse - and how many people are involved.

                                </li>
                                <br/>


                                <li>
                                    <h2>
                                        JOURNEY WORK
                                    </h2>

                                    Journey work is a guided meditation in which we observe the issues with detachment. In a journey, the client is guided into a safe place to look at the challenges they face and learn techniques to overcome those challenges. It is a shamanic based meditation, in which we use the water of life and the tree of life. In these journeys, the client will meet their Spirit Guide and/or Medicine Power Animal. Journeys are very empowering and they release a lot of emotional and mental baggage.

                                </li>
                                <br/>

                                <li>
                                    <h2>
                                        SOUL RETRIEVAL

                                    </h2>
                                    Soul Retrieval is very similar to a Journey, and is often used in healing a serious trauma. It is literally searching for a fraction of the client’s Spirit – which has gone walkabout due to the severity of the trauma experienced, leaving the client feeling empty and depressed.  This is normally 3 sessions.
                                </li>
                                <br/>

                                <li>
                                    <h2>
                                        DISTANT HEALING

                                    </h2>

                                    Using energy to connect with the client and doing distant energy healing, with the aid of angels and ancestors.
                                </li>
                                <br/>
                                <li>
                                    <h2>

                                        SPACE CLEARING


                                    </h2>
                                    For the past  25 years, as an initiated shaman and Energy Consultant, I have engaged in clearing homes and businesses of negative influences and demonic disturbances. Poltergeists, Demons (Tokolosch) and ‘Attachments’. To remove a demonic influence is highly specialized work and not to be undertaken by any ‘novice’. It does involve a dangerous element of possession. To help a lost spirit into the light or remove negative energy does not entail the same risk. After doing a ‘Space Clearing’ the client is also advised of Feng Shui and the natural way to create harmony and peace in the space cleansed.

                                </li>
                                <br/>
                                <li>
                                    <h2>

                                        COACHING AND MENTORSHIP

                                    </h2>
                                    These are approximately 2 hour sessions per week. In these sessions we explore shamanism as a way of life. The student will be incredibly empowered and within a very short time, able to manifest a reality which is both positive and beneficial.
                                    I work to the pace and interest of the student. Should the student desire to become a shaman and connect to their own psychic ability, then the process will take longer.


                                </li>
                                <br/>
                                <li>
                                    <h2>

                                        SHAMANIC WORKSHOPS, TALKS AND TEAM BUILDING
                                    </h2>

                                    This will be discussed when an inquiry is made.
                                </li>
                                <br/>

                            </ul>
                        </nav>
                    </div>
                    {close}
                </article>

                <article
                    id="testimonials"
                    className={`${this.props.article === 'testimonials' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                    }`}
                    style={{display: 'none'}}
                >
                    <h2 className="mysticalEffect major ">Testimonials</h2>
                    <span className="image main">
            <img src={table} alt=""/>
          </span>
                    <div>

                        <ul>
                            <li>
                                My whole life I have carried a family curse and my endeavours always ended up with
                                problems and negativity. Jenha worked with me for 21 days and now the curse is gone and
                                I feel like a new person. And like a drain unblocked all the good things are now pouring
                                through!
                            </li>
                            <li>
                                Jenah sent me an invitation to become her student after she performed some consulting work for me.
                                I graciously accepted her offer and was a student for approximately 6 months.
                                Jenha has vast knowledge and experience related to Shamanism and easily transfers her
                                skill sets to willing students. She has taught me a number of sharmanic techniques,
                                which I am able to use and practice in my daily living experience.
                                Jenha is patient and works at the students pace from grass roots all the way to advanced
                                techniques. In the teaching process you will also gain a life long friend as well
                                as advice from the ancestors. It really is an amazing learning experience. If you suffer
                                from depression and anxiety her teachings will teach you how to control those mental
                                health problems. Thanks so much Jenha.
                            </li>
                            <li>
                                This was the BEST session I've had in my whole life of going for counselling. Most
                                productive meaning and enjoyable. My deepest Thanks to Spirit and Jenha. This is money
                                best invested.
                            </li>
                            <li>

                                After a session I felt lighter, happier. I was no longer caught up in my own little
                                drama. I felt shifted.
                            </li>
                            <li>

                                Jenha takes her time with me and it feels like a visit - much more personal than a
                                scheduled timed session. Sessions are engaging.
                            </li>
                            <li>

                                The Journey: Gratitude to body parts highlighted how little appreciation I have for
                                them. Very clear in terms of what you see, hear, feel. I feel centered now and not
                                scattered.
                            </li>
                            <li>
                                Thanks Jenha, for always giving sound advice and insight! Love you 🙂
                            </li>
                            <li>
                                You are truly gifted, I appreciate your honesty, I don't feel like you're just a
                                spiritual teacher but a caring mom / guide...THANK YOU Jenha. I hope to share your
                                teachings with those I interact with... You stripped me bare, never cried, laughed so
                                much as I did in the past month and weeks...so many emotions that had to leave me.
                            </li>
                        </ul>


                    </div>
                    {close}
                </article>

                <article
                    id="pricing"
                    className={`${this.props.article === 'pricing' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                    }`}
                    style={{display: 'none'}}
                >
                    <h2 className=" major">Investment & Pricing</h2>
                    <span className="image main">
            <img src={table} alt=""/>
          </span>
                    <div>
                        <p>
                            None of my fees are carved in stone and discounts are offered to students, pensioners and the unemployed.
                            It is always best to inquire and make appointments, via WA or a call, during normal office hours.
                        </p>

          <p>
         The prices are negotiable, but here are the current rates.
              <ul>
<li>Full readings R800 and R900 for couples.</li>
                  <li>Video Live Reading - R1200</li>
                  <li>Your Week at a glance - a weekly reading done once a week for a month. R2500.</li>
<li>Removal of attachment and energy shift - R4000.</li>
<li>Breaking of curses and spells -  approximately R5000 upward depending on severity of situation and how many people involved. </li>
<li>Journey Work and Soul Retrieval - R4000 and up depending on severity</li>
<li>Distant Healing - R400 per hour</li>
<li>Space Clearing and Blessings - From R4000</li>
<li>Coaching and Mentorship - R4000 per month.</li>
              </ul>

          </p>
                    </div>
                    {close}
                </article>

                <article
                    id="contact"
                    className={` ${this.props.article === 'contact' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                    }`}
                    style={{display: 'none'}}
                >
                    <h2 className="mysticalEffect major">Contact Jenha</h2>
                    <h4>
                        Phone : + +27 83 5505 326
                    </h4>
                    <h4>
                        Email : psychicjenha@gmail.com
                    </h4>
                    <h5>

                        MON-FRI 09:00 – 17:00
                        <br/>
                        <br/>
                        SAT 9:00 – Noon
                    </h5>


                    <ul className="icons">
                    </ul>
                    {close}
                </article>
            </div>
        )
    }
}

Main.propTypes = {
    route: PropTypes.object,
    article: PropTypes.string,
    articleTimeout: PropTypes.bool,
    onCloseArticle: PropTypes.func,
    timeout: PropTypes.bool,
    setWrapperRef: PropTypes.func.isRequired,
}

export default Main

import PropTypes from 'prop-types'
import React from 'react'
import icon from '../images/icon.ico';

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>

      <div style={{display: 'flex', alignItems: 'center', justifyContext: 'center'}}>

          <img style={{width: '115px'}} src={icon} alt="" />
      </div>
    <div className="content">

        <div className="inner">
            <h1 className="mysticalEffect">Psychic Jenha</h1>
            <p>
                An authentic Psychic experience
                <br />
            </p>
        </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('services')
            }}
          >
            Services
          </button>
        </li>
          <li>
              <button
                onClick={() => {
                    props.onOpenArticle('pricing')
                }}
              >
                  Investment
              </button>
          </li>
          <li>
              <button
                  onClick={() => {
                      props.onOpenArticle('testimonials')
                  }}
              >
                  Testimonials
              </button>
          </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
